body {
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 15px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

.footer {
  height: 20vh;
}

.textarea {
  width: 661.06px;
}

.textarea {
  resize: none;
  height: 400px;
  width: 725px;
}

.savebtn {
  margin-left: 600px;
}

.urlArea {
  resize: vertical
}

.sideContent {
  max-width: 300px;
  width: 20vw;
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 6px;
  margin-left: 1px;
  min-width: 10vh;
}

.APIKey {
  font-weight: bolder;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  resize: vertical;
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 4px;
  min-width: 20%;
}

.APIKeyHeader {
  width: 20vw;
  resize: vertical;
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 4px;
}

.sideArea {
  margin-top: 10px;
  height: 90vh;
  width: 320px;
}

.Input {
  border: none;
  border-color: none;
  width: 100vw;
  height: 90vh;
}

.inputHeader {
  padding-bottom: 35px;
  font-size: 1.25rem;
  -webkit-font-smoothing: antialiased;
  font: inherit;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  font-style: normal;
  font-weight: bold;
}

.footerHeader {
  font-weight: bolder;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
}

.sideContentTextArea {
  border: solid 1px;
  max-width: 300px;
  width: 20vw;
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 6px;
  margin-left: 1px;
  min-width: 10vh;
  min-height: 50px;
}

.outputContainer {
  min-height: 100%;
  min-width: 280px;
  vertical-align: top;
  box-shadow: 0px 0px 1px 1px grey;
  background-color: #333333;
  color: white;
  padding: 15px;
  border-radius: 5px;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  max-width: 430px;
}

.inputTd {
  min-width: 305px;
  min-height: 100%;
  margin-bottom: 20px;
  vertical-align: top;
  float: left;
}


.containerHeader {
  margin-top: 1px;
  margin-bottom: 1px;
}

.requestField {
  border: solid 1px;
  height: inherit;
  margin: 10px;
  padding: 5px;
  height: 400px;
  width: 720px;
  line-height: .9rem;
  font-size: 0.85rem;
  overflow-y: scroll;
}

.codeBox {
  height: inherit;
  margin: 10px;
  padding: 5px;
  height: 5vh;
  width: 400px;
  line-height: 0.8rem;
  font-size: 0.85rem;
}

.InputNumber {
  margin-bottom: 20px;
  background-color: #f4f4f4;
}

.InputComboBox {
  margin-bottom: 20px;
  background-color: #f4f4f4;
}

.InputBox {
  max-width: 280px;
  box-shadow: 0px 0px 1px 1px grey;
  border-radius: 5px;
  margin-right: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.Header {
  width: 100%;
  min-width: 250px;
  font-size: xx-large;
  font-weight: bold;
}

.headerInsurance {
  margin-block-start: 0px;
  margin-block-end: 15px;
  display: block;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  font-size: 0.75em;
  font-weight: normal;
  line-height: 1.1;
}

.foldBtn {
  float: right
}

.container {
  margin-left: 22px;
  margin-right: 22px;
  width: 800px;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
}

.disclaimer {
  clear: left;
  width: 100%;
  max-width: 590px;
  min-width: 250px;
  margin-right: 10px;
  margin-block-start: 10px;
  margin-block-end: 0px;
  display: block;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  font-size: 0.71rem;
  font-weight: normal;
  line-height: 1rem;
  letter-spacing: 0.01875em;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  color: grey;
  hyphens: auto;
  text-align: justify;
}

.insurance {
  border-top: 1px solid black;
}

.insuranceTypography {
  margin-top: 0px;
  font-weight: bolder;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
}

.InputContainer {
  max-width: 360px;
}

.test5 {
  margin-top: 7px;
}

.copyButton {
  float: right;
  margin-right: 50px;
  color: #008DFC;
}

.test7 {
  margin: -15px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.outputText {
  background-color: rgba(0, 0, 0, 0.06)
}

.inputComboboxHeader {
  font-weight: bold;
  color: rgb(0, 84, 149);
}

.inputNumberHeader {
  font-weight: bold;
  color: rgb(0, 84, 149);
}

.outputTextHeader {
  font-weight: bold;
  color: rgb(0, 84, 149);
}

.InputComboBoxInsurance {
  background-color: rgba(0, 0, 0, 0.06);
}

.outputHeader {
  text-align: justify;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 15px;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
}

.insuranceInfo {
  max-width: 100%;
  margin-block-start: 0px;
  margin-block-end: 20px;
  margin-right: 5px;
  display: block;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  font-size: 0.71rem;
  font-weight: normal;
  hyphens: auto;
  text-align: justify;
  line-height: 1rem;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  color: grey;
}

.insuranceBody {
  margin-top: -25px;
}


.downPaymentMessage {
  padding: 5px;
  max-width: 800px;
  margin-block-start: -20px;
  margin-block-end: 5px;
  display: block;
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  font-size: 0.62rem;
  font-weight: normal;
  hyphens: auto;
  line-height: 1rem;
  font-style: normal;
  color: grey;
}

.ARC_Widget {
  width: 100%;
  max-width: 600px;
  min-width: 300px;
}

.bold {
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  font-size: inherit;
  font-weight: bolder;
  margin-top: 10px;
  margin-bottom: 20px;
}

.notBold {
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  margin-top: 10px;
  margin-bottom: 1px;
}

.codeBoxData {
  margin-left: 20px;
  width: 400px;
  float: left;
}

.test55 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.test66 {
  max-width: 300px;
  width: 300px;
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 1px;
  margin-left: 1px;
}

.ECC-Select {
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  width: 15vw;
  max-width: 300px;
  margin: 25px;
  margin-top: 10px;
}

.ECC-Input {
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  width: 15vw;
  max-width: 300px;
  margin: 25px;
  margin-top: 10px;
}

.ECC-Button {
  margin: 25px;
  margin-top: 10px;
}

.ECC-FlexBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ECC-FlexColumn {
  margin-top: 15px;
  width: 20vw;
  max-width: 500px;
}

.ECC-Flexbox {
  margin-left: 25px;
}

.customerType {
  font-family: "MBCorpo Text", "DaimlerCS-Regular", "DaimlerCSArab-Regular", sans-serif;
  font-size: 18px;
}

.CustomerType {
  margin-left: 25px;
}
